import React from "react"
import Header from "./header"
import Footer from "./footer"
import BurgerMenu from "./burger"
import PageTransition from 'gatsby-v2-plugin-page-transitions';

const Layout = ({ children }) => {
  return (
    <>
      <Header/>
      <BurgerMenu/>
        <main>
        <PageTransition>
          {children}
        </PageTransition>
        </main>
      <Footer/>
    </>
  )
}

export default Layout
