import React from "react"

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-sm-6">
            <h3 className="font-medium">Got an idea?</h3>
            <h2>let’s work together.</h2>
            <h3>bonsai@bonsai.is</h3>
          </div>
        </div>
      </div>
      <div className="container-fluid d-flex">
        <div className="social_media d-flex justify-content-end ml-auto">
            <h3>Street cred</h3>
            <a href="https://www.facebook.com/bonsai.is" add target="_blank" rel="noreferrer">
              <img src="../../facebookIcon.svg" alt="Facebook Icon"/>
            </a>
            <a href="https://www.instagram.com/bonsai.is/" add target="_blank" rel="noreferrer">
              <img src="../../instagramIcon.svg" alt="Instagram Icon"/>
            </a>
            <a href="https://www.linkedin.com/company/bonsai-is/" add target="_blank" rel="noreferrer">
              <img src="../../linkedinIconWhite.svg" alt="Linkedin Icon"/>
            </a>
            <a href="https://dribbble.com/danniing" add target="_blank" rel="noreferrer">
              <img src="../../dribbbleIcon.svg" alt="Dribbble Icon"/>
            </a>
          </div>
      </div>
    </footer>
  )
}

export default Footer
