/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'
import { useScrollPosition } from '../hooks/useScrollPosition';
import burgerIcon from '../../static/burger-icon.svg';
import burgerClose from '../../static/close.svg';
const isBrowser = typeof window !== `undefined`;

const BurgerMenu = () => {
  const [ menuActive, setMenuState ] = useState(false);
  const { y } = useScrollPosition();
  const url = typeof window !== 'undefined' ? window.location.href : '';

  let opacity = 1;
  if (isBrowser) {
    opacity = 1 - (y * 2 / window.innerHeight);
  }

  useEffect(() => {
    if (menuActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'visible';
    }
  }, [menuActive])

  return (
    <div className="burgerMenu">
      <div tabIndex={0} className="button-unstyled burgerBar d-flex h-100 flex-column " role="button" onClick={() => setMenuState(!menuActive)}>
        <Link to="/" class="burgerLogo">
          <img src="../../bonsaiLogo.svg" alt="bonsai logo"/>
        </Link>

        <img src={menuActive === true ? burgerClose : burgerIcon} alt="burger icon" class="burgerIcon m-auto"/>
        <img style={{ opacity }} src="../../arrow-down.svg" alt="arrow down" class="burgerArrow"/>
      </div>
      <div className={`burgerNav h-100 ${menuActive === true ? "active" : ""}`}>
        <ul className="list-unstyled d-flex justify-content-center flex-column">
          <li>
            <Link
              to="/about"
              className={`burgerItem ${url.includes('/about') ? 'active-burgerItem' : ''}`}
            >
              about
            </Link>
          </li>
          <li>
            <Link
              to="/successes"
              className={`burgerItem ${url.includes('/successes') ? 'active-burgerItem' : ''}`}
            >
              successes
            </Link>
          </li>
          <li>
            <Link
              to="/blog"
              className={`burgerItem ${url.includes('/blog') ? 'active-burgerItem' : ''}`}
            >
              blog
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default BurgerMenu
