import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import ArboriaBook from '../../static/fonts/ArboriaBook.ttf';
import ArboriaMedium from '../../static/fonts/ArboriaMedium.ttf';
import RobotSlab from '../../static/fonts/RobotoSlab-ExtraLight.ttf';

function SEO({ description, lang, meta, title, url, image, keywords, author }) {

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      url={url}
      image={image}
      title={title}
      titleTemplate={`%s | Bonsai`}
      script={[
        {
          async: true,
          src: 'https://www.googletagmanager.com/gtag/js?id=UA-150959108-2'
        },
        {
          type: 'text/javascript',
          innerHTML: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'UA-150959108-2');`
        }
      ]}
      meta={[
        {
          name: `keywords`,
          content: keywords,
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: `Bonsai - `+title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          property: 'og:url',
          content: url
        },
        {
          property: 'og:image',
          content: image
        },
        {
          name: `author`,
          content: author,
        },
      ].concat(meta)}
    >
      <link
        rel="preload"
        as="font"
        href={ArboriaBook}
        type="font/ttf"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={ArboriaMedium}
        type="font/ttf"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={RobotSlab}
        type="font/ttf"
        crossOrigin="anonymous"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  author: `bonsai.is`,
  lang: `en`,
  meta: [],
  description: `We create extraordinary websites`,
  keywords: `bonsai, website, prismic, gatsby, next, react, react native, vefsíða, heimasíða, wordpress, tækni, blog, fyrirtæki, verkefni, Iceland, Ísland, brand, logo, ui, ux, success, projects, portfolio, SEO, pagespeed, google, apple, app store, play store, arnar freyr kristjánsson, daníel ingvarsson, software team, trust, experience`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  keywords: PropTypes.string,
}

export default SEO
